<template>
<div class="game">
	<common-header
		type="allow"
		@click="$router.back()"
	>
		<h1 class="common-header-title__heading">台風をやっつけよう！</h1>
	</common-header>

	<!-- ゲームエリア -->
	<div class="game-container" id="js_game_container">
		<section class="game__box">
			<div class="game__box--inner">
				<ul
					class="game__box--row"
					v-for="(items, y) in computedCells"
					:key="`row-${y}`"
				>
					<li
						v-for="(item, x) in items"
						:key="item.key"
						:class="[
							'game__box--cell',
							item.status ? 'is-sun' : 'is-typhoon',
							`is-${boardSize}`,
						]"
						@click="onClickCell(y, x)"
					>
						<transition name="character">
							<img
								v-show="item.status"
								class="game__box--img"
								src="@/assets/img/game/game_typhoon_sun.png"
							/>
						</transition>
						<transition name="character">
							<img
								v-show="!item.status"
								class="game__box--img"
								src="@/assets/img/game/game_typhoon_typhoon.png"
							/>
						</transition>
					</li>
				</ul>
			</div>
		</section>
		<nav class="game__nav">
			<ul class="game__nav--list">
				<li class="game__nav--item" @click="isTutorial = true"><i class="sprite btn01" title="遊び方" /></li>
				<li class="game__nav--item" @click="init"><i class="sprite btn02" title="はじめから遊ぶ" /></li>
			</ul>
		</nav>
	</div>
	<!-- /ゲームエリア -->

	<!-- 遊び方 -->
	<v-ons-modal
		:visible="isTutorial"
		animation="fade"
		class="game__tutorial"
	>
		<section class="game__tutorial--inner">
			<h2 class="game__tutorial--title">遊び方<a class="game__tutorial--close" @click="isTutorial = false"><ons-icon icon="md-close" /></a></h2>
			<p class="game__tutorial--text">
				1つのマスをタップすると、そのマスと上下左右のマスの色が反転します。<br>
				その作業をくりかえし、すべてのマスを「晴れ」にしたらゲームクリアです。<br><br>
				ゲームクリアすると頭痛ーるキャラクターのスタンプがゲットできます。<br><br>
				別の問題に挑戦したいときは「はじめから遊ぶ」を押してください。
			</p>
		</section>
	</v-ons-modal>
	<!-- /遊び方 -->

	<!-- クリア -->
	<v-ons-modal
		:visible="computedComplete"
		animation="fade"
		class="game__complete"
	>
		<section class="game__complete--inner">
			<h2 class="game__complete--title"><img src="@/assets/img/game/game_typhoon_img.png" alt="おめでとうございます！"></h2>
			<p class="game__complete--text">かわいい頭痛ーるキャラクターの<br>台風スタンプをプレゼント！</p>
			<a href="javascript:void(0);" class="game__complete--link" @click="onLinkClick($event)" id="trackingTyphoonPresent"><i class="sprite btn03" title="プレゼントをもらう" /></a>
		</section>
	</v-ons-modal>
	<!-- /クリア -->
</div>
</template>

<script>
// ライブラリ
import cmnConst from '@/assets/js/constant.js'

// Compornents
import CommonHeader from '@/components/Molecules/CommonHeader'

export default {
	name: 'GameTyphoon',
	components: {
		CommonHeader
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			boardSize: 3,
			cells: [],
			isTutorial: false
		}
	},
	created () {
		this.init()
	},
	computed: {
		computedCells () {
			return this.cells
		},

		computedComplete () {
			let isComplete = true

			this.cells.forEach(row => {
				row.forEach(cell => {
					if (!cell.status) isComplete = false
				})
			})
			// isComplete = true	// DEBUG

			return isComplete
		}
	},
	mounted () {
	},
	beforeDestroy () {
	},
	methods: {
		// 初期化
		init () {
			for (let y = 0, lenY = this.boardSize; y < lenY; y++) {
				const arr = []
				for (let x = 0, lenX = this.boardSize; x < lenX; x++) {
					const status = Math.floor(Math.random() * 2)
					const key = `${y + 1}-${x + 1}`
					arr[x] = { status: status === 0, key: key }
				}
				this.$set(this.cells, y, arr)
			}
		},

		onClickCell (y, x) {
			if (this.cells.length === 0) return false

			const arr = this.cells

			// 押されたセル
			arr[y][x].status = !arr[y][x].status

			// X軸の更新
			if (x - 1 > -1) arr[y][x - 1].status = !arr[y][x - 1].status
			if (x + 1 < this.boardSize) arr[y][x + 1].status = !arr[y][x + 1].status

			// Y軸の更新
			if (y - 1 > -1) arr[y - 1][x].status = !arr[y - 1][x].status
			if (y + 1 < this.boardSize) arr[y + 1][x].status = !arr[y + 1][x].status
		},

		// プレゼントをもらうリンククリック
		onLinkClick (event) {
			event.preventDefault()
			this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=stamp`, _stampType: 'typhoon', _backPage: 'top' } })
		}
	}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/variable";

// NOTE: Gameページ限定の変数（デザインシステム外のため他での利用禁止）
$background-sun: #FD9F5A;
$background-typhoon: #60DA98;
$color-primary: #000;
$color-secondary: #F3EBEB;
$spacing-5: 0.312rem;
$spacing-13: 0.812rem;
$spacing-15: 0.937rem;
$spacing-18: 1.125rem;
$spacing-35: 2.187rem;
$spacing-70: 4.375rem;
$font-size-22: 1.375rem;

.game {
	padding-top: 44px;	// NOTE: ヘッダー分の高さを確保
	background: url(~@/assets/img/lp_typhoon2021/bg.png) repeat-y 0 0;
	background-size: 100% auto;
	box-sizing: border-box;
	font-family: $lp-family-primary;

	&__box {
		margin: $spacing-20 $spacing-13 0;
		border: 5px solid $primary;
		box-sizing: border-box;

		&--inner {
			padding: $spacing-10 $spacing-20;
			background-color: $background-primary;
		}

		&--row {
			display: flex;
			justify-content: center;
			align-items: center;
			list-style: none;
			margin: 0 auto;
			padding: 0;
			width: 100%;
		}

		&--cell {	// 3x3
			position: relative;
			width: calc(30.0973%);
			padding-top: calc(36.7856%);
			margin: $spacing-5 0;
			border-radius: 10px;
			transition: all 0.3s;

			&:nth-last-of-type(2) { margin: $spacing-5 $spacing-10; }

			&.is-sun { background-color: $background-sun; }

			&.is-typhoon { background-color: $background-typhoon; }
		}

		&--img {
			width: 80%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
		}
	}

  &__nav {
		width: 100%;
		padding: $spacing-20 $spacing-13 $spacing-70;
		margin: 0 auto;
		box-sizing: border-box;
		max-width: 375px;

		@media (max-width: (360px)) {
			width: 320px;
		}

		&--list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0;
			margin: 0;
			list-style: none;
			color: $primary;
		}

		&--item {
			width: 48.7064%;
			padding: 0;

			& > i {
				@media (max-width: (360px)) {
					transform: scale(.853);
					transform-origin: 0 0;
				}
			}
		}
	}

	&__tutorial {
		position: fixed;

		/deep/ .modal__content {
			padding-top: 75px;
			vertical-align: top;
			font-family: $lp-family-primary;
		}

		&--inner {
			width: 93.6%;
			padding: 0;
			margin: 0 auto;
			box-sizing: border-box;
			border: 3px solid $primary;
			border-radius: 10px;
			background-color: $background-primary;
		}

		&--title {
			position: relative;
			padding: $spacing-10 $spacing-16;
			margin: 0;
			font-weight: normal;
			font-size: $font-size-24;
			line-height: $line-height-1;
			color: $color-secondary;
			background-color: $primary;
		}

		&--close {
			position: absolute;
			display: block;
			top: 50%;
			right: $spacing-16;
			transform: translateY(-50%);
		}

		&--text {
			padding: $spacing-20 $spacing-16 $spacing-20 $spacing-20;
			margin: 0;
			color: $color-primary;
			text-align: left;
			font-size: $font-size-14;
		}
	}

	&__complete {
		position: fixed;

		/deep/ .modal__content {
			padding-top: 35px;
			vertical-align: top;
			font-family: $lp-family-primary;
		}

		&--inner {
			width: 93.6%;
			padding: 0 0 $spacing-35 0;
			margin: 0 auto;
			box-sizing: border-box;
			border: 3px solid $primary;
			border-radius: 10px;
			background-color: $background-primary;
		}

		&--title {
			margin: 0;

			& > img { width: 100%; }
		}

		&--text {
			margin: $spacing-13 auto $spacing-18;
			color: $color-primary;
			font-size: $font-size-14;
		}

		&--link {
			display: block;
			width: 270px;
			margin: 0 auto;
		}
	}
}

// スプライト
.sprite {
	background-image: url(~@/assets/img/game/sprite_game_typhoon.png);
	background-size: (550px / 2) (390px / 2);
	background-repeat: no-repeat;
	display: block;
}

.sprite.btn01 {
	width: (340px / 2);
	height: (120px / 2);
	background-position: (-5px / 2) (-5px / 2);
}

.sprite.btn02 {
	width: (340px / 2);
	height: (120px / 2);
	background-position: (-5px / 2) (-135px / 2);
}

.sprite.btn03 {
	width: (540px / 2);
	height: (120px / 2);
	background-position: (-5px / 2) (-265px / 2);
}

// トランジション
.character-enter-active,
.character-leave-active {
  transition: opacity 0.3s;
}
.character-enter,
.character-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
