<template>
	<article class="game-container" id="js_game_container">
		<!-- NOTE: 今後ゲームが増えたらLP.vueと同様に表示するコンポーネントをpropsをみて分岐させる -->
		<game-typhoon @contentHeight="contentHeightController()" />
	</article>
</template>

<script>
// Compornents
import GameTyphoon from '@/components/Organisms/GameTyphoon'

export default {
	name: 'Game',
	components: {
		GameTyphoon
	},
	data () {
		return {
		}
	},
	props: {
		gameType: {
			type: String,
			default: ''
		}
	},
	computed: {
	},
	mounted () {
		// console.log('gameType: ', this.gameType)
	},
	beforeDestroy () {
	},
	methods: {
		// コンテンツの高さをemitする処理
		// NOTE: 現在、コンテンツの高さを確保する処理の見直しのため子コンポーネントからemitしないようにしている。未使用だが機能は残しておく。
		contentHeightController () {
			this.$nextTick(() => {
				const domContainer = document.getElementById('js_game_container')
				if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
			})
		}
	}
}
</script>
